import React, {
  FC,
  useCallback,
} from 'react';
import { cn } from '@/lib';
import { I18N_CODES } from '@/lib/constants/general';
import { useRouter, useTranslation } from '@/middleware/i18n';
import { Button } from '@/components/ui/Button';
import { typography } from '@/components/ui/typography';
import { IconConfettiBackground } from '@/components/ui/icons/IconConfettiBackground';
import { Maybe } from '@/controllers/graphql/generated';
import { AchievementIcon } from '@/components/platform/Achievements';
import { AchievementsEvents } from '@/controllers/achievements/achievements.events';
import { FlashMessageProps } from '@/components/ui/flashMessage/typedefs';
import { FlashMessageBaseWrapper } from '@/components/ui/flashMessage/FlashMessageBaseWrapper';
import { UserAchievementFullFragment } from '@/modules/achievements/communication/generated/userAchievementFull.fragment.generated';
import { renderRoute } from '@/controllers/router/type-safe-routes/renderRoute';
import { allRoutes } from '@/controllers/router/allRoutes';
import styles from './AchievementFlashMessageItem.module.scss';

type Props = FlashMessageProps<Maybe<UserAchievementFullFragment>>;

export const AchievementFlashMessageItem: FC<Props> = React.memo<Props>(
  ({
    message,
    updateTimer,
    clearTimer,
    setTimer,
    removeMessage,
    closeDelay,
    setCloseDelay,
  }) => {
    const { t } = useTranslation([I18N_CODES.common]);

    const router = useRouter();

    const handleOpenAchievementClick = useCallback(() => {
      removeMessage(message);

      const selectedId = message.data?.achievement.id;
      const achievement = message.data?.achievement.name || '';

      AchievementsEvents.sendEvent(
        AchievementsEvents.events.flashMessageOpenButtonClicked,
        { achievement },
      );

      router.push(
        renderRoute(allRoutes.achievements, {
          selectedId,
        }),
      );
    }, [
      router,
      removeMessage,
      message,
    ]);

    return (
      <FlashMessageBaseWrapper
        data-qa={`flash-message-${message.type}`}
        message={message}
        updateTimer={updateTimer}
        clearTimer={clearTimer}
        setTimer={setTimer}
        removeMessage={removeMessage}
        closeDelay={closeDelay}
        setCloseDelay={setCloseDelay}
        withTimerAnimation
      >
        <IconConfettiBackground className={styles.confettiBackground} />

        <div
          data-qa="flash-message-icon"
          className={styles.achievementIconWrapper}
        >
          <AchievementIcon
            name={message.data?.achievement?.name}
            iconUnlockedUrl={message.data?.achievement?.iconUnlocked?.url}
            withShadow={false}
            unlocked
          />
        </div>

        <div className={styles.contentWrapper} data-qa='achievement-message-content'>
          <p
            data-qa="flash-message-heading"
            className={cn(
              typography.platformH3,
              styles.heading,
            )}
          >
            {t(`${I18N_CODES.common}:achievement_unlocked_heading_${message.heading}`)}
          </p>

          <p
            data-qa="flash-message-content"
            className={cn(
              typography.platformTextSecondary,
              styles.description,
            )}
          >
            {t(`${I18N_CODES.common}:${message.text}_description`)}
          </p>

          <div className={styles.buttonWrapper} data-qa='achievement-message-button'>
            <Button
              data-qa={`flash-message-${message.type}-open-button`}
              onClick={handleOpenAchievementClick}
              mode={Button.mode.Secondary}
              size={Button.size.Small}
              text={t(`${I18N_CODES.common}:open_achievements_page_button`)}
            />
          </div>
        </div>
      </FlashMessageBaseWrapper>
    );
  },
);
